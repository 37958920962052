body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  /* Prevent selection */
  /* Safari */
  -webkit-user-select: none;
  /* IE 10 and IE 11 */
  -ms-user-select: none;
  /* Standard syntax */
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
#root {
  height: 100%;
}

.slight-shadow {
  text-shadow: rgb(114, 114, 114) 0px 0px 2px;
}